<template>
  <el-upload
    v-model:file-list="fileList"
    ref="upload"
    :limit="1"
    :auto-upload="false"
    :on-change="changeUpload"
    accept=".jpg,.png"
    action=""
    list-type="picture-card"
    class="upload-image"
    :class="{ hide: hideUpload }"
  >
    <el-icon><Plus /></el-icon>
    <template #file="{ file }">
      <div v-if="media_url" class="image-file">
        <img class="el-upload-list__item-thumbnail" :src="media_url" alt="" />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="dialogVisible = true">
            <el-icon><zoom-in /></el-icon>
          </span>
          <span class="el-upload-list__item-delete" @click="removeImage(file)">
            <el-icon><Delete /></el-icon>
          </span>
        </span>
      </div>
      <span v-if="media_name" class="image-name">
        檔名：{{ media_name?.length > 20 ? `${media_name.slice(0, 17)}...` : media_name }}
      </span>
    </template>
  </el-upload>
  <!-- 圖片放大 -->
  <el-dialog v-model="dialogVisible" width="80%" :destroy-on-close="true">
    <img :src="media_url" alt="Preview Image" style="width: 100%" />
  </el-dialog>
</template>
<script>
import { uploadFile } from '@/api/common';
import { ref, computed, getCurrentInstance } from 'vue';
export default {
  name: 'upload-image',
  props: ['file_url', 'file_name'],
  emits: ['update:file_url', 'update:file_name'],
  setup(props, context) {
    const { proxy } = getCurrentInstance();
    const upload = ref(null);
    const fileList = ref([]);
    const hideUpload = ref(false);
    const dialogVisible = ref(false);
    const media_url = computed({
      get: () => props.file_url,
      set: (val) => context.emit('update:file_url', val),
    });
    const media_name = computed({
      get: () => props.file_name,
      set: (val) => context.emit('update:file_name', val),
    });
    //初始化
    if (media_url.value) {
      fileList.value.push({ name: media_name.value, url: media_url.value });
      hideUpload.value = true;
    }
    //圖片檢查
    const checkImage = (file) => {
      hideUpload.value = true;
      const isLtMB = file.size / 1024 / 1024 <= 1;
      if (!isLtMB) {
        upload.value.handleRemove(file);
        proxy.$message.error('圖片大小不得超過1MB');
        hideUpload.value = false;
        return Promise.reject();
      }
      const isSize = new Promise(function (resolve, reject) {
        let width = 1280;
        let height = 1024;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.src = _URL.createObjectURL(file.raw);
        img.onload = function () {
          let valid = img.width === width && img.height === height;
          valid ? resolve() : reject();
        };
      }).then(
        () => {
          return Promise.resolve();
        },
        () => {
          upload.value.handleRemove(file);
          proxy.$message.error(`圖片尺寸不符合`);
          hideUpload.value = false;
          return Promise.reject();
        }
      );
      return isLtMB && isSize;
    };
    //上傳圖片
    const changeUpload = async (file) => {
      await checkImage(file);
      try {
        const base64 = await getBase64(file.raw);
        const res = await uploadFile({ filename: file.name, file: base64.split(',')[1], path: 'slideshow' });
        media_url.value = res.url;
        media_name.value = file.name;
      } catch (e) {
        upload.value.handleRemove(file);
        hideUpload.value = false;
      }
    };
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };
    //刪除圖片
    const removeImage = (file) => {
      media_url.value = null;
      media_name.value = null;
      upload.value.handleRemove(file);
      hideUpload.value = false;
    };

    return {
      upload,
      fileList,
      hideUpload,
      media_url,
      media_name,
      dialogVisible,
      changeUpload,
      removeImage,
    };
  },
};
</script>
<style lang="scss" scoped>
.image-file {
  @include flex-center-center;
}
.image-name {
  position: absolute;
  bottom: -30px;
  font-size: 14px;
}
:deep(.el-upload--picture-card) {
  --el-upload-picture-card-size: 300px;
}
:deep(.el-upload-list--picture-card) {
  --el-upload-list-picture-card-size: 300px;
}
.hide :deep(.el-upload--picture-card) {
  display: none;
}
.upload-image {
  :deep(.el-upload-list__item) {
    overflow: visible;
  }
}
</style>
